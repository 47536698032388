<template>
    <div style="position: relative; overflow: hidden">
        <a
            href="https://yandex.ru/maps/org/turoperator_rvm/154833972367/?utm_medium=mapframe&utm_source=maps"
            style="color: #eee; font-size: 12px; position: absolute; top: 0px"
            >Туроператор РВМ</a
        ><a
            href="https://yandex.ru/maps/2/saint-petersburg/category/tour_operator/184106430/?utm_medium=mapframe&utm_source=maps"
            style="color: #eee; font-size: 12px; position: absolute; top: 14px"
            >Туроператор в Санкт‑Петербурге</a
        ><iframe
            src="https://yandex.ru/map-widget/v1/?ll=30.335859%2C59.935139&mode=poi&poi%5Bpoint%5D=30.335708%2C59.935023&poi%5Buri%5D=ymapsbm1%3A%2F%2Forg%3Foid%3D154833972367&z=20.82"
            width="100%"
            height="600"
            frameborder="1"
            allowfullscreen="true"
            style="position: relative"
        ></iframe>
    </div>
</template>
